import React from "react";
import BaseLayout from "layouts/base-layout";
import "scss/main.scss";
import "scss/vendor/carousels.scss";
import renderModules from "helpers/render-modules";

export default function Blog(props) {
  console.log(props);
  const { content, settings, meta } = props.pageContext;
  
  return (
    <BaseLayout
      data={settings}
      meta={meta || {}}
    >
      { content && renderModules(content) }
    </BaseLayout>
  );
}
